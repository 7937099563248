.headerImage {
  max-height: 40vh;
}

.headerImageSignUp {
  max-width: 30vw;
}

.aboutImage {
  max-width: 100vw;
}

.hero {
  max-height: 55vh;
}

@media screen and (max-width: 810px) {
  .aboutImage {
    max-width: 80vw;
    margin-left: 10vw;
  }
}

@media screen and (max-width: 576px) {
  .headerImage {
    max-height: 30vh;
  }
  .headerImageSignUp {
    max-width: 90vw;
  }
  .text-xs-center {
    text-align: center !important;
  }
  .justify-content-xs-around {
    justify-content: space-around !important;
  }
  .mb-xs-4 {
    margin-bottom: 4vh;
  }
  .px-xs-3 {
    padding-left: 3%;
    padding-right: 3%;
  }
  .py-xs-3 {
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .map-overlay {
    position: relative !important;
    margin-right: 0 !important;
    background: #fff;
    font-family: Arial, sans-serif;
    border-radius: 3px;
  }
  .aboutImage {
    max-width: 100vw;
    min-height: 100vh;
    margin-left: 0;
  }
  .h100 {
    height: 100vh;
  }
  .h85 {
    height: 85vh;
  }
  #campaignLabel {
    width: 100% !important;
    height: 250px !important;
  }
  .sm-p-1 {
    padding: 1rem !important;
  }
  .sm-p-0 {
    padding: 0 !important;
  }
  .sm-px-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .sm-mx-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .sm-ps-1 {
    padding-left: 1rem !important;
  }
  .sm-pe-1 {
    padding-right: 1rem !important;
  }
  .sm-py-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .sm-flex-col {
    display: flex !important;
    flex-direction: column !important;
  }
  .sm-justify-center {
    justify-content: center !important;
  }
  .sm-h-250 {
    height: 250px !important;
  }
  .sm-h-auto {
    height: auto !important;
  }
  .sm-w-auto {
    width: auto !important;
  }
  .sm-text-center {
    text-align: center !important;
  }
  .grid-container {
    grid-template-columns: auto !important;
  }
  .sm-w-100 {
    width: 100% !important;
  }
  .sm-w-90 {
    width: 90% !important;
  }
  .hero {
    min-height: 140vh;
  }
}

.text-justify {
  text-align: justify;
}

.map-overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #fff;
  margin-right: 20px;
  font-family: Arial, sans-serif;
  overflow: auto;
  border-radius: 3px;
}

.legend-key {
  display: inline-block;
  border-radius: 20%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border: 1px solid black;
}

.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 9999;
}

[data-x-axis]::before {
  content: attr(data-x-axis);
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  bottom: 0;
  font-size: 11px;
  color: #777;
}

[data-y-axis]::after {
  content: attr(data-y-axis);
  position: absolute;
  top: 50%;
  left: -20px;
  font-size: 11px;
  color: #777;
  text-align: center;
  transform: rotate(-90deg) translateY(50%);
}

.icon-SeparatistAttack {
  fill: red;
}

.fs-italic {
  font-style: italic;
}

.zoom {
  transition: transform 0.2s; /* Animation */
}

.zoom:hover {
  transform: scale(1.25);
}

.h100 {
  height: 100vh;
}

@media screen and (min-width: 768px) {
  .tweet-feed {
    max-height: 800px;
    overflow-y: scroll;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
}

.grid-item {
  text-align: center;
}

.flex {
  display: flex;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}
